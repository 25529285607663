<template>
  <div class="hfull">
    <div class="p-3" style="display: flex; justifycontent: right">
      <button class="btn btn-danger" @click="close">X</button>
    </div>
    <div class="header pt-lg-5">
      <b-container>
          <div class="header-body text-center mb-7 text-white">
              <b-row class="justify-content-center">
              <img
                  src="../../../../public/img/brand/img-logo-color.png"
                  alt="klhk"
                  class="mb-2"
              />
              <b-col md="12">
                  <h2 class="text-white">
                      PELAPORAN KENDALA PANDEMI COVID-19
                  </h2>
                  <p class="text-lead text-black">
                  Pelaporan Kendala PANDEMI COVID-19 hanya dapat diisi khusus
                  untuk Penanggung jawab usaha dan/atau kegiatan yang mengalami
                  kendala pemasangan dan pengoperasian SPARING dikarenakan Pandemi
                  COVID-19 , maka wajib memenuhi ketentuan sesuai Surat Edaran
                  Menteri Lingkungan Hidup Nomor SE.5/Menlhk/Setjen/KUM.1/6/2020
                  tentang Protokol Pelaksanaan Pemantauan dan Pelaporan Kualitas
                  Lingkungan Pada Masa Pandemi COVID-19.
              </p>
              </b-col>
              </b-row>
              <button class="btn btn-primary" @click="showAnnoncementModal = true">
                  LIHAT KETENTUAN
              </button>
          </div>
      </b-container>
    </div>

    <!-- Pendaftaran Baru -->
    <b-container class="mt-5 pb-5">
      <b-row class="justify-content-center">
        <b-col md="12">
            <b-card>
                
                <div class="pl-5 pr-5 mt-4">
                    <h3 class="mb-3 text-center">Informasi Umum</h3>
                    <div> 
                    <!-- Nama perusahaan -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Nama Perusahaan</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Nama Perusahaan"
                          rules="required"
                          v-model="userInput.namaPerusahaan"
                        />
                      </div>
                    </div>

                      <!-- Jenis Industri -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Jenis Industri</label>
                      <div class="col-lg-9">
                        <base-input name="Jenis Industri" rules="required">
                          <el-select v-model="userInput.compType">
                            <el-option
                              v-for="option in provideSelect.companyType"
                              :key="option.id"
                              :label="option.name"
                              :value="option.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>

                     <!-- Alamat perusahaan -->
                     <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Alamat Perusahaan</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Alamat Perusahaan"
                          rules="required"
                          v-model="userInput.AlamatPerusahaan"
                        />
                      </div>
                    </div>

                     <!-- Provinsi -->
                     <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Provinsi</label>
                      <div class="col-lg-9">
                        <base-input name="Provinsi" rules="required">
                          <el-select
                            v-model="userInput.Provinsi"
                            @change="
                              getCityDepentOnProvince(userInput.Provinsi)
                            "
                            filterable
                          >
                            <el-option
                              v-for="option in provideSelect.provinsi"
                              :key="option.id"
                              :label="option.name"
                              :value="option.id"
                            />
                          </el-select>
                        </base-input>
                      </div>
                    </div>

                    <!-- Kabupaten / Kota -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Kabupaten / Kota</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Kabupaten / Kota"
                          rules="required"
                        >
                          <el-select v-model="userInput.Kabkot" filterable>
                            <el-option
                              v-for="option in provideSelect.kabupatenKota"
                              :key="option.id"
                              :label="option.name"
                              :value="option.id"
                            />
                          </el-select>
                        </base-input>
                      </div>
                    </div>

                     <!-- Nomor Telepon Kantor -->
                     <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Nomor Telepon Kantor</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Nomor Telepon Kantor"
                          rules="required"
                          v-model="userInput.NomorTeleponKantor"
                          type="tel"
                        />
                      </div>
                    </div>

                    <!-- Email kantor -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Email Kantor</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Email Kantor"
                          rules="required"
                          v-model="userInput.EmailKantor"
                          type="email"
                          autocomplete="username email"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Kontak Personal Sparing</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Nama Penanggung Jawab Peserta Uji"
                          rules="required"
                          v-model="userInput.NamaPenanggungJawabPesertaUji"
                        />
                      </div>
                    </div>

                    <!-- Nomor Telepon penanggung jawab uji -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Nomor Handphone</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Nomor Telepon Penanggun Jawab Uji"
                          rules="required"
                          v-model="userInput.NomorTeleponPenanggungJawabUji"
                          type="tel"
                        />
                      </div>
                    </div>

                    <!-- Alamat email penanggung jawab uji -->
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Email Kontak Person</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Alamat Email Penanggun Jawab Uji"
                          rules="required"
                          v-model="userInput.AlamatEmailPenanggungJawabUji"
                          type="email"
                          autocomplete="username email"
                        />
                      </div>
                    </div>

                    <!-- Nomor SIUP -->
                    <!-- <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Nomor SIUP</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Nomor Siup"
                          rules="required"
                          v-model="userInput.nomorSiup"
                        />
                      </div>
                    </div> -->

                    <!-- Nama penanggung jawab sesuai dengan SIUP -->
                  </div>
                </div>

                <!-- <form-wizard @onComplete="submit">
                  <tab-content
                    title="Upload Bukti Pemasangan SPARING Terkait COVID-19"
                    :selected="true"
                  > -->
                    <div class="p-5">
                      <div class="mb-5">
                        Upload Bukti Pemasangan SPARING Terkait COVID-19
                      </div>
                      <div>
                        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                          <b-form role="form" @submit.prevent="handleSubmit(submitProgressReport)">
                            <!-- Tahap pemasangan sparing -->
                            <div class="form-group row">
                              <label class="col-3 col-form-label form-control-label">Tahapan Pemasangan SPARING</label>
                              <div class="col-lg-9">
                                <base-input name="Tahapan Pemasangan SPARING" rules="required">
                                  <select class="form-control" v-model="userInput.tahapan" @change="showUploadForm">
                                    <option v-for="option, idx in dummySelect.tahap" :key="idx" :value="option.value" >{{ option.label }}</option>
                                  </select>
                                </base-input>
                                <small class="d-block mt--3 text-justify">
                                  <strong>Tender</strong>, belum ada pemenang tender
                                  dan jadwal pemasangan, belum terpasang
                                  <br />
                                  <strong>Pemasangan</strong>, sudah ada pemenang
                                  tender dan jadwal pemasangan, belum terpasang
                                  <br />
                                  <strong>Pengoperasian</strong>, sudah terpasang
                                </small>
                              </div>
                            </div>
                            
                            <!-- Bukti pengadaan dokumen sparing -->
                            <template>
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Bukti Pengadaan Sparing (Dokumen Tender)</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_bukti_pengadaan_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_bukti_pengadaan_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_bukti_pengadaan_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_bukti_pengadaan_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
                            </template>
      
                            <!-- =============== PEMASANGAN =============================== -->

                            <!-- Pemenang Sparing -->
                            <template v-if="provideSelect.uploadChoosen == 'Pemasangan'">
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Pemenang Sparing</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_pemenang_sparing_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_pemenang_sparing_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_pemenang_sparing_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_pemenang_sparing_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
      
                              <!-- Jadwal Pemasangan sparing -->
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Jadwal Pemasangan Sparing</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_jadwal_pemasangan_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_jadwal_pemasangan_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_jadwal_pemasangan_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_jadwal_pemasangan_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
                            </template>

                            <!-- =============== PENGOPERASIAN =============================== -->
      
                            <template v-if="provideSelect.uploadChoosen == 'Pengoperasian'">
                              <!-- Foto Instalasi -->
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Foto Instalasi</label>
                                <div class="col-lg-9">
                                  <!-- <base-input name="Foto Instalasi">
                                    <file-input
                                      accept="image/x-png, image/jpeg"
                                      ref="pelaporan_foto_instalasi_file"
                                      @change="uploadFile('pelaporan_foto_instalasi_file', 'pelaporan_foto_instalasi_file')"
                                    ></file-input>
                                  </base-input> -->

                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_foto_instalasi_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_foto_instalasi_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_foto_instalasi_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_foto_instalasi_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
      
                              <!-- Foto Spaaring -->
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Foto Sparing</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_foto_sparing_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_foto_sparing_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_foto_sparing_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_foto_sparing_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
      
                              <!-- Dokumen Pemasangaan -->
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Dokumen Pemasangan</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_dokumen_pemasangan_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_dokumen_pemasangan_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_dokumen_pemasangan_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_dokumen_pemasangan_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
      
                              <!-- Perencanaan Masa uji / Commisioning -->
                              <div class="form-group row">
                                <label class="col-3 col-form-label form-control-label">Perencanaan Masa Uji / Commisioning</label>
                                <div class="col-lg-9">
                                  <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_perencanaan_masa_uji_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_perencanaan_masa_uji_file')"
                                    ></file-input>
                                  </base-input>
                                  <small v-if="file.pelaporan_progress.pelaporan_perencanaan_masa_uji_file"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_perencanaan_masa_uji_file)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                </div>
                              </div>
      
                              <!-- Rencana Pengoperasian Sparing -->
                              <div class="form-group row" >
                                <label class="col-3 col-form-label form-control-label">Rencana Pengoperasian Sparing</label>
                                <div class="col-lg-9">
                                  <base-input name="Rencana Pengoperasian Sparing">
                                    <base-input name="Bukti Pengadaan Sparing">
                                    <file-input
                                      accept="application/pdf"
                                      ref="pelaporan_rencana_pengoperasian_uji_file"
                                      @change="uploadFileBlob(file.pelaporan_progress, 'pelaporan_rencana_pengoperasian_uji_file')"
                                    ></file-input>
                                    </base-input>
                                    <small v-if="file.pelaporan_progress.pelaporan_rencana_pengoperasian_uji_file"
                                        class="d-block mt-3 mb-2 text-justify">
                                        <a :href="getBlobsFile(file.pelaporan_progress.pelaporan_rencana_pengoperasian_uji_file)"
                                          target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                  </base-input>
                                </div>
                              </div>

                            </template>
                            <div style="display: flex;justify-content: center;">
                              <button class="btn btn-primary mt-5 w-50" type="submit">KIRIM</button>
                            </div>
                          </b-form>
                        </validation-observer>
                      </div>
                    </div>
                  <!-- </tab-content>
                </form-wizard> -->
            </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Edit Pelaporan -->

    <!-- modal -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="announcement-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header myflex">
          <img
              src="../../../../public/img/brand/img-logo-color.png"
              alt="klhk"
              class="mb-2"
            />
          <h3 class="ml-3">Kementerian <br> Lingkungan Hidup dan Kehutanan <br> Republik Indonesia</h3>
        </div>
      </template>
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contents">
          <div class="p-4" style="overflow-y: scroll;height: 500px;">
              <h2 class="text-justify"></h2>
              <p class="mb-2 text-justify">
                Pelaporan Kendala PANDEMI COVID-19 hanya dapat diisi khusus
                untuk Penanggung jawab usaha dan/atau kegiatan yang
                <b>
                  mengalami kendala pemasangan dan pengoperasian SPARING
                  dikarenakan Pandemi COVID-19 </b
                >, maka wajib memenuhi ketentuan sesuai Surat Edaran Menteri
                Lingkungan Hidup Nomor SE.5/Menlhk/Setjen/KUM.1/6/2020 tentang
                Protokol Pelaksanaan Pemantauan dan Pelaporan Kualitas
                Lingkungan Pada Masa Pandemi COVID-19.
              </p>
              <p>Ketentuan dalam SE. 5/Menlhk/Setjen/KUM.1/6/2020 yaitu:</p>
              <ol>
                <li>
                  Sudah melaksanakan ketentuan Pasal 2 Peraturan Menteri
                  Lingkungan Hidup Nomor P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang
                  Pemantauan Kualitas Air Limbah Secara Terus Menerus Dan Dalam
                  Jaringan Bagi Usaha Dan/Atau Kegiatan jo Peraturan Menteri
                  Lingkungan Hidup Nomor P.80/MENLHK/SETJEN/KUM.1/10/2019 dengan
                  menyampaikan bukti pengadaan SPARING (dokumen tender)
                </li>
                <li>
                  Bagi perusahaan yang sudah selesai melaksanakan pengadaan
                  SPARING tetapi terkendala dalam pemasangan SPARING wajib
                  menyampaikan: bukti pemenang dan jadwal pemasangan SPARING
                </li>
                <li>
                  Bagi perusahaan yang sudah memasang SPARING tetapi belum
                  mengoperasikan SPARING wajib menyampaikan bukti berupa:
                  instalasi SPARING (foto instalasi, foto alat sensor, dokumen
                  pemasangan), rencana masa uji coba/commissioning, rencana
                  pengoperasian SPARING
                </li>
                <li>
                  Bukti sebagaimana dimaksud dalam angka 1-3 dan peraturan
                  pemberlakuan Pembatasan Sosial Berskala Besar (PSBB) setiap
                  daerah disampaikan kepada Kementerian Lingkungan Hidup dan
                  Kehutanan Up. Direktur Jenderal Pengendalian Pencemarandan
                  Kerusakan Lingkungan, JL. D.I Panjaitan Kav.24 Kebon Nanas
                  Jakarta Timur, Gedung B lantai 4.
                </li>
              </ol>
              <p>
                Surat dan bukti sebagaimana angka 4 dapat di unggah/upload
                melalui fitur Pelaporan Kendala PANDEMI COVID-19. Penanggung
                jawab usaha dan/atau kegiatan yang terkendala pandemi COVID-19
                dapat mendapatkan USER NAME, PASSWORD, UID (User Identification)
                setelah menyelesaikan pemasangan dan pengoperasian, kemudian
                melaksanakan pendaftaran di fitur pendaftaran laman
                <a href="http://sparing.ppkl.menlhk.go.id"
                  >http://sparing.ppkl.menlhk.go.id</a
                >.
              </p>
              <br />
              <span>
                Tim SPARING<br />
                Direktorat Pengendalian Pencemaran air<br />
                JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
                Gedung B lantai 5<br />
                Email: sparing.menlhk@gmail.com<br />
                Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS,
                jam pelayanan hari kerja 08.00-15.00 WIB)<br />
              </span>
            </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
   
  </div>
</template>


<script>
  import FileInput from "@/components/Inputs/FileInput";
  import Modal from "@/components/Modal.vue";
  import API from "../../../api/base_url";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import baseURL from "@/api/base_url_backend.js";
  import { Select, Option } from "element-ui";

  
  export default {
    name: "pelaporanCovid",
    components: {
      FileInput,
      Modal,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    mounted() {
      this.getSelectFormValue();
      this.getCompanyType();
    },
    data() {
      return {
        userInput: {
        tahapan:"",
        nomorSiup: "",
        namaPerusahaan: "",
        lampiranSiup: "",
        NamaPenanggungJawabSesuaiDenganSiup: "",
        AlamatPerusahaan: "",
        jalan: "",
        Provinsi: "",
        Kabkot: "",
        NomorTeleponKantor: "",
        EmailKantor: "",
        NamaPenanggungJawabPesertaUji: "",
        NomorTeleponPenanggungJawabUji: "",
        NamaDirektur: "",
        AlamatEmailPenanggungJawabUji: "",
        logger: [{ brand: null, type: null }],
        
        compType: ""
        },
        file: {
          pelaporan_progress: {
            pelaporan_bukti_pengadaan_file: '',
            pelaporan_rencana_pengoperasian_uji_file: '',
            pelaporan_perencanaan_masa_uji_file: '',
            pelaporan_dokumen_pemasangan_file: '',
            pelaporan_foto_sparing_file: '',
            pelaporan_foto_instalasi_file: '',
            pelaporan_jadwal_pemasangan_file: '',
            pelaporan_pemenang_sparing_file: ''
          },
          uji_konek: {
            ujikonek_siup_file: '',
            ujikonek_brosur_file: '',
            ujikonek_brand_path: ''
          }
        },
        showAnnoncementModal: false,
        dummySelect: {
            tahap: [
              { label: "Tender", value: "Tender"},
              { label: "Pemasangan", value: "Pemasangan"},
              { label: "Pengoperasian", value: "Pengoperasian"},
            ],
        },
        provideSelect: {
          provinsi:[],
          kabupatenKota:[],
          daftarPerusahaan:[],
          tmp:[],
          uploadChoosen:null
        },
        formData: new FormData(),
      };
    },
    methods: {
      getCompanyType() {
      API.get("comp-types")
        .then(({ data: content }) => {
          this.provideSelect.companyType = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
      },
      getSelectFormValue() {
      API.get(`provinces`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCityDepentOnProvince(data) {
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
       // start upload
      uploadFileBlob(form, field) {
          const fileInput = this.$refs[field];
          const file = fileInput.files[0];

          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
          if (!allowedTypes.includes(file.type)) {
            Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
            return;
          }

          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
            return;
          }

          const formData = new FormData();
          formData.append("file", file);
          this.isLoading = true;
          API.post(`blobs/upload/public`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              let data = res.data.data;
              let value = data.id;
              this.$set(form, field, value);
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
            });
      },
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
      },
      resetAllFileInputs(refs) {
        refs.forEach(ref => this.resetFileInput(ref));
      },
      // end of upload
      goBack() {
        this.$router.push("/pelaporan-progress");
      },
      close() {
        this.$router.push("/pilih-pendaftaran");
      },
      showUploadForm() {
        this.provideSelect.uploadChoosen = this.userInput.tahapan
      },
      submitProgressReport() {
        Swal.fire({
          title: "Kirim Pelaporan Progress?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM",
          cancelButtonText: "BATAL"
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }

            this.formData.append("installation_progress", this.userInput.tahapan );

            let payload = {
              files: {      
                commission_file_id: this.file.pelaporan_progress.pelaporan_perencanaan_masa_uji_file,
                jadwal_file_id: this.file.pelaporan_progress.pelaporan_jadwal_pemasangan_file,
                pemasangan_file_id: this.file.pelaporan_progress.pelaporan_dokumen_pemasangan_file,
                pemenang_file_id: this.file.pelaporan_progress.pelaporan_pemenang_sparing_file,
                pengoperasian_file_id: this.file.pelaporan_progress.pelaporan_rencana_pengoperasian_uji_file,
                pic_pemasangan_file_id: this.file.pelaporan_progress.pelaporan_foto_instalasi_file,
                pic_sparing_file_id: this.file.pelaporan_progress.pelaporan_foto_sparing_file,
                tender_file_id: this.file.pelaporan_progress.pelaporan_bukti_pengadaan_file
              },
              tahapan: this.userInput.tahapan
            }

            API.post(`progress-reports`, payload, { headers })
              .then(({data:content}) => {
                Swal.fire('Data berhasil ditambahkan', '', 'success')
                this.reset()
                this.$router.push("/pelaporan-progress");
              })
              .catch((err) => {
                Swal.fire('Gagal ditambahkan', 'Data gagal didaftarkan', 'error')
              })
          }
        });
      },
      reset() {
        this.formData = new FormData()
        this.userInput.tahapan = ""
      }
    },
  };
  </script>